<script setup lang="ts">
import { computed } from 'vue'
import type { SearchResultItem } from '@/api/models/types'

interface Props {
  result?: { [item: string]: SearchResultItem[] }
  loading: boolean
}

defineOptions({ inheritAttrs: false })
const props = defineProps<Props>()
const model = defineModel<string>()

const count = computed(() => {
  if (model.value && props.result && props.result[model.value]) {
    return Object.keys(props.result[model.value]).length
  } else {
    return 0
  }
})
</script>

<template>
  <div>
    <div class="text-gray-700 font-light text-xs pt-4" v-if="model && result">
      {{ $t('glossary.showingResult') }} {{ count }}
    </div>
    <div class="max-h-96 overflow-y-auto" v-bind="$attrs">
      <table v-if="model && result" class="w-full">
        <thead class="font-medium text-xs text-gray-700">
          <td class="pb-1">{{ $t('glossary.box') }}</td>
          <td class="pb-1">{{ $t('glossary.city') }}</td>
          <td class="pb-1">{{ $t('glossary.zipCode') }}</td>
          <td class="pb-1 text-center">{{ $t('glossary.hit') }}</td>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in result[model]"
            :key="index"
            class="overflow-hidden text-sm border-t border-gray-100"
          >
            <td class="max-w-40 py-1.5 truncate">
              {{ item.label }}
            </td>
            <td class="max-w-20 truncate">
              {{ item.address }}
            </td>
            <td class="max-w-20 truncate">
              {{ item.zipCode }}
            </td>
            <td class="max-w-20 truncate text-center">
              <span class="bg-success text-white px-2 p-1">{{ item.hitWordAreaType }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="count === 0" class="font-light text-sm text-gray-700 text-center pt-4">
      {{ $t('message.noSearchTermMatch') }}
    </div>
  </div>
</template>
