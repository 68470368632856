<script setup lang="ts">
import { onMounted, onUnmounted, ref, watch } from 'vue'
import { useSystemMaintenanceStore } from '../stores/system-maintenance'
import { mdiUpdate, mdiArrowRightBold } from '@mdi/js'
import { useSelected } from '../../motion/stores/selected'
import { useSessionStore } from '@/shared/stores/session'
import { SYS_MAINT_NOTIF_INTERVAL, SYS_MAINT_CHECK_DURATION_LONG, SYS_MAINT_CHECK_DURATION_SHORT } from '@/constants'
import { useMessageStore } from '@/shared/stores/message'
import { WARNING } from '@/constants'
import { storeToRefs } from 'pinia'
import { useClientStore } from '@/modules/client/store/client'
import { ExposedApplicationSettings } from '@/api/models/definitions'
import { useSideMenuStore } from '@/modules/side-menu/stores/side-menu'
import SvgIcon from '@/shared/components/SvgIcon.vue'
import PrimaryButton from '@/shared/components/PrimaryButton.vue'
import DialogPrompt from '@/shared/components/DialogPrompt.vue'

const isSystemMaintenaceOpen = ref<boolean>(false)
const intervalId = ref<NodeJS.Timeout>()
const systemMaintenanceStore = useSystemMaintenanceStore()
const { current, next } = storeToRefs(systemMaintenanceStore)
const lastNotif = ref<number>(0)
const messageStore = useMessageStore()
const selectedStore = useSelected()
const sessionStore = useSessionStore()
const clientStore = useClientStore()
const sideMenuStore = useSideMenuStore()

const startSysMainPoll = (): void => {
  intervalId.value = setInterval(doPoll, SYS_MAINT_CHECK_DURATION_LONG)
}

const doPoll = async (): Promise<void> => {
  await systemMaintenanceStore.doMonitorPoll()
  if (current.value) {
    selectedStore.resetAll()
    sideMenuStore.close()
    sessionStore.lockApi()
    isSystemMaintenaceOpen.value = true
    clearInterval(intervalId.value)
    intervalId.value = setInterval(doPoll, SYS_MAINT_CHECK_DURATION_SHORT)
  } else if (next.value) {
    const notifInterval =
      clientStore.getIntValueSetting(ExposedApplicationSettings.PreMaintenanceHintInterval, SYS_MAINT_NOTIF_INTERVAL) *
      60000
    if (lastNotif.value === 0 || Date.now() - lastNotif.value > notifInterval) {
      messageStore.showToast(WARNING, next.value.message, false)
      lastNotif.value = Date.now()
    }
    clearInterval(intervalId.value)
    intervalId.value = setInterval(doPoll, SYS_MAINT_CHECK_DURATION_SHORT)
  }
}

const gotoLobby = (): void => {
  const hostAuthUrl = import.meta.env.VITE_AUTH_BASE_URL
  window.location.assign(hostAuthUrl + `/maintenance`)
}

onMounted(() => {
  startSysMainPoll()
  setTimeout(doPoll, 5000)
})

onUnmounted(() => clearInterval(intervalId.value))

watch(
  () => current.value,
  () => (isSystemMaintenaceOpen.value = !!current.value),
)
</script>

<template>
  <DialogPrompt :title="$t('glossary.systemMaintenance')" v-model:open="isSystemMaintenaceOpen">
    <div class="flex items-center justify-center px-4">
      <span class="font-light text-sm text-gray-700">{{ current?.message }}</span>
    </div>
    <div class="flex items-center justify-center text-sm px-4 text-gray-700 font-bold" v-if="!current?.isUnscheduled">
      {{ current?.activeFrom }}
      <SvgIcon :path="mdiArrowRightBold" class="fill-success h-5 w-5 mx-1.5" />
      {{ current?.activeTo }}
    </div>
    <div class="p-4">
      <PrimaryButton
        :text="$t('glossary.seeMaintenanceStatus')"
        :icon="mdiUpdate"
        class="w-full justify-center"
        @click="gotoLobby()"
      />
    </div>
  </DialogPrompt>
</template>
