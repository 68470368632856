import { useApi } from '..'
import { useUserStore } from '@/modules/user/stores/user'
import type { ClientErrorData, SearchResultResponse } from '../models/types'

export const useSearchApi = () => {
  const api = useApi()
  const user = useUserStore()

  const getSearchResult = async (
    query: string,
  ): Promise<{ data: SearchResultResponse | null; error: ClientErrorData | null }> =>
    await api.request.get<SearchResultResponse>(`view/${user.viewId}/search/mbox?query=${query}`, false)

  return { getSearchResult }
}
