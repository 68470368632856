export const isRequired = (value: string | undefined): boolean => {
  return !value || value.length === 0
}

export const isEmail = (value: string | undefined): boolean => {
  if (value && value.length > 0) {
    const pattern = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g
    return !pattern.test(value)
  }
  return false
}

export const isMaxLength = (value: string | undefined, max: number): boolean => {
  return value && value.length <= max ? true : false
}
