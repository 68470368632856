import { ref } from 'vue'
import { defineStore } from 'pinia'
import { useMessageStore } from './message'
import { useSessionApi } from '@/api/services/session'
import { ERROR } from '@/constants'

export const useSessionStore = defineStore('session', () => {
  const api = useSessionApi()
  const messageStore = useMessageStore()
  const accessToken = ref<string>('')
  const apiLocked = ref<boolean>(false)

  const checkForSessionResume = async (): Promise<boolean> => {
    const { data, error } = await api.getResume()
    if (error) {
      messageStore.showToast(ERROR, error.message)
      return false
    }
    if (data) {
      accessToken.value = data.accessToken!
      return true
    }
    return false
  }

  const refreshAccessToken = async (): Promise<boolean> => {
    const { data, error } = await api.getAccessToken()
    if (error) {
      messageStore.showToast(ERROR, error.message)
      return false
    }
    if (data) {
      accessToken.value = data.accessToken!
      return true
    }
    return false
  }

  const lockApi = () => {
    apiLocked.value = true
  }
  const unLockApi = () => {
    apiLocked.value = false
  }
  const isApiLocked = () => apiLocked.value

  return { accessToken, checkForSessionResume, refreshAccessToken, lockApi, unLockApi, isApiLocked }
})
