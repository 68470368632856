import { ref } from 'vue'
import { defineStore } from 'pinia'
import { formatISO, subDays } from 'date-fns'
import { useUserStore } from '@/modules/user/stores/user'
import type { ShipmentFilter, UserViewFilters } from '@/api/models/types'
import { ApplicationViewKey } from '@/api/models/definitions'
import { DefaultMBoxFilter } from '@/api/models/defaults'

export const useFilterStore = defineStore('filter', () => {
  const userStore = useUserStore()
  const filter = ref<ShipmentFilter>({
    fromViewDate: formatISO(new Date()),
    toViewDate: formatISO(new Date()),
    status: [1, 2, 3, 4, 5, 6, 7],
    carrierId: undefined,
    countryId: undefined,
    serviceGroupId: undefined,
    zipZoneId: undefined,
    dimension1Id: undefined,
    dimension2Id: undefined,
    dimension3Id: undefined,
  })

  const setUserFilterSettings = (userViewSettings: UserViewFilters | undefined): void => {
    if (userViewSettings) {
      switch (userStore.viewId) {
        case ApplicationViewKey.Warehouse: {
          filter.value = DefaultMBoxFilter
          break
        }
        case ApplicationViewKey.Agent:
        case ApplicationViewKey.Outbound: {
          const {
            zoneId,
            carrierId,
            countryId,
            serviceGroupId,
            selectNumberOfDays,
            dimension1Id,
            dimension2Id,
            dimension3Id,
            status,
          } = userViewSettings.defaultOutbound
          if (selectNumberOfDays) {
            filter.value.fromViewDate = formatISO(subDays(new Date(), selectNumberOfDays))
          }
          filter.value.toViewDate = formatISO(new Date())
          filter.value.carrierId = carrierId
          filter.value.countryId = countryId
          filter.value.serviceGroupId = serviceGroupId
          filter.value.zipZoneId = zoneId
          filter.value.dimension1Id = dimension1Id
          filter.value.dimension2Id = dimension2Id
          filter.value.dimension3Id = dimension3Id
          filter.value.status = status
          break
        }
        default:
          filter.value = { ...DefaultMBoxFilter }
      }
    }
  }

  const clearAll = (): void => {
    filter.value.carrierId = undefined
    filter.value.countryId = undefined
    filter.value.serviceGroupId = undefined
    filter.value.zipZoneId = undefined
    filter.value.dimension1Id = undefined
    filter.value.dimension2Id = undefined
    filter.value.dimension3Id = undefined
  }

  return { filter, setUserFilterSettings, clearAll }
})
