<script setup lang="ts">
import { ref } from 'vue'
import { storeToRefs } from 'pinia'
import {
  mdiUpdate,
  mdiArrowRightThin,
  mdiClock,
  mdiTrayArrowDown,
  mdiInformationOutline,
  mdiChevronDown,
} from '@mdi/js'
import { useVersionStore } from '../stores/version'
import { vOnClickOutside } from '@vueuse/components'
import SvgIcon from '@/shared/components/SvgIcon.vue'
import UnstyledButton from '@/shared/components/UnstyledButton.vue'
import PrimaryButton from '@/shared/components/PrimaryButton.vue'
import DefaultButton from '@/shared/components/DefaultButton.vue'

const versionStore = useVersionStore()
const { updateMode } = storeToRefs(versionStore)
const currentVersion = ref<string>(__APP_VERSION__)
const isActive = ref<boolean>(false)

const refreshApp = (): void => {
  updateMode.value.isDefault = false
  location.reload()
}
</script>

<template>
  <div
    class="relative transition-opacity ease-in-out duration-200"
    :class="updateMode.isDefault ? 'opacity-100' : 'opacity-0 collapse'"
    v-on-click-outside="() => (isActive = false)"
  >
    <div v-if="updateMode.isDefault" class="flex items-center ms-2">
      <UnstyledButton
        :title="$t('glossary.update')"
        class="inline-flex bg-secondary-dark text-secondary-light border border-secondary-dark hover:shadow-inner px-2 py-1"
        @click="isActive = !isActive"
      >
        <SvgIcon :path="mdiTrayArrowDown" class="fill-secondary-light h-5 w-5" />
        <span class="text-sm mx-1.5">{{ $t('glossary.update') }}</span>
        <SvgIcon :path="mdiChevronDown" class="fill-secondary-light h-5 w-5" />
      </UnstyledButton>
    </div>
    <div
      class="z-40 absolute right-0 mt-0.5 origin-top-right border border-gray-300 bg-white transition-all transform ease-in-out duration-200 focus:outline-none text-nowrap shadow-md p-4"
      :class="[isActive ? 'scale-100 opacity-100' : 'scale-75 opacity-0 collapse']"
    >
      <div class="pb-3">
        <div class="flex items-center border-b border-gray-300 pb-1">
          <SvgIcon :path="mdiInformationOutline" class="fill-gray-700 h-5 w-5 me-1" />
          <span class="font-medium text-sm">
            {{ $t('message.newUpdateAvailable', [versionStore.version, versionStore.buildId]) }}
          </span>
        </div>
        <div class="flex items-center text-xs pt-3">
          <p>
            {{ $t('glossary.currentVersion') }}
            <span class="font-bold">{{ currentVersion }}</span>
          </p>
          <SvgIcon :path="mdiArrowRightThin" class="fill-success h-5 w-5" />
          <p>
            {{ $t('glossary.newVersion') }}
            <span class="font-bold">{{ versionStore.version }}</span>
          </p>
        </div>
      </div>
      <div class="flex items-center justify-between gap-2">
        <DefaultButton :text="$t('glossary.updateLater')" :icon="mdiClock" @click="isActive = false" />
        <PrimaryButton :text="$t('glossary.updateNow')" :icon="mdiUpdate" @click="refreshApp()" />
      </div>
    </div>
  </div>
</template>
