import { ref } from 'vue'
import { defineStore } from 'pinia'
import type { ApplicationViewBrandingSetup, OurboxesTargetUriSetup } from '../../api/models/types'

export const useThemeStore = defineStore('theme', () => {
  const brand = ref<ApplicationViewBrandingSetup>()

  const changeTheme = (theme?: ApplicationViewBrandingSetup): void => {
    const root = document.querySelector<HTMLElement>(':root')
    if (root) {
      root.style.setProperty('--color-primary-light', theme ? theme.primaryTextColor : '#ffffff')
      root.style.setProperty('--color-primary-dark', theme ? theme.primaryColor : '#53ab47')
      root.style.setProperty('--color-secondary-light', theme ? theme.secondaryTextColor : '#ffffff')
      root.style.setProperty('--color-secondary-dark', theme ? theme.secondaryColor : '#242426')
      root.style.setProperty('--color-accent-light', theme ? theme.footerTextColor : '#ffffff')
      root.style.setProperty('--color-accent-dark', theme ? theme.footerColor : '#242426')
      brand.value = theme
    }
  }

  const setDefaultTheme = (viewId: number | undefined, views: OurboxesTargetUriSetup[]): void => {
    const getView = () => views.find((view: OurboxesTargetUriSetup) => view.appViewId === viewId)
    let view = getView()
    if (!view) {
      if (views.length === 0) {
        window.location.assign(`${import.meta.env.VITE_AUTH_BASE_URL}`)
        return
      }
      viewId = views[0].appViewId
      view = getView()
    }
    changeTheme(view && view.theme ? view.theme : views[0].theme)
  }

  return { brand, changeTheme, setDefaultTheme }
})
