import { useApi } from '..'
import { useUserStore } from '@/modules/user/stores/user'
import type {
  ClientErrorData,
  MBoxDetailsRequest,
  TrackingUrlResponse,
  OrderLinesResponse,
  OrderLineDetailsRequest,
  OrderLineResponse,
  MboxDetailsSectionResponse,
} from '../models/types'

export const useSideMenuApi = () => {
  const api = useApi()
  const user = useUserStore()

  const fetchSideMenuInformationGeneral = async (
    payload: MBoxDetailsRequest,
  ): Promise<{ data: MboxDetailsSectionResponse | null; error: ClientErrorData | null }> => {
    return await api.request.post(`view/${user.viewId}/side-menu/information-general`, false, JSON.stringify(payload))
  }

  const fetchSideMenuInformationShipment = async (
    payload: MBoxDetailsRequest,
  ): Promise<{ data: MboxDetailsSectionResponse | null; error: ClientErrorData | null }> => {
    return await api.request.post(`view/${user.viewId}/side-menu/information-shipment`, false, JSON.stringify(payload))
  }

  const fetchSideMenuInformationRecipient = async (
    payload: MBoxDetailsRequest,
  ): Promise<{ data: MboxDetailsSectionResponse | null; error: ClientErrorData | null }> => {
    return await api.request.post(`view/${user.viewId}/side-menu/information-recipient`, false, JSON.stringify(payload))
  }

  const fetchSideMenuProducts = async (
    payload: MBoxDetailsRequest,
  ): Promise<{ data: OrderLinesResponse | null; error: ClientErrorData | null }> =>
    await api.request.post(`view/${user.viewId}/side-menu/order-lines`, false, JSON.stringify(payload))

  const fetchSideMenuShipments = async (
    payload: MBoxDetailsRequest,
  ): Promise<{ data: MboxDetailsSectionResponse | null; error: ClientErrorData | null }> =>
    await api.request.post(`view/${user.viewId}/side-menu/shipments`, false, JSON.stringify(payload))

  const fetchTrackingUrl = async (
    objectId: string,
  ): Promise<{ data: TrackingUrlResponse | null; error: ClientErrorData | null }> =>
    await api.request.get<TrackingUrlResponse>(`view/${user.viewId}/side-menu/trackingurl?objectId=${objectId}`, false)

  const fetchImage = async (imageId: string): Promise<{ data: Blob | null; error: ClientErrorData | null }> =>
    await api.request.getBlob<Blob>(`view/${user.viewId}/attachment/${imageId}/get-image`)

  const fetchSideOrderLineDetails = async (
    payload: OrderLineDetailsRequest,
  ): Promise<{ data: OrderLineResponse | null; error: ClientErrorData | null }> =>
    await api.request.post(`view/${user.viewId}/side-menu/order-line-details`, false, JSON.stringify(payload))

  return {
    fetchSideMenuInformationGeneral,
    fetchSideMenuInformationShipment,
    fetchSideMenuInformationRecipient,
    fetchSideMenuProducts,
    fetchSideMenuShipments,
    fetchTrackingUrl,
    fetchSideOrderLineDetails,
    fetchImage,
  }
}
