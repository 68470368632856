<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import type { Client } from '@/api/models/types'
import { mdiChevronDown } from '@mdi/js'
import { vOnClickOutside } from '@vueuse/components'
import SvgIcon from '@/shared/components/SvgIcon.vue'
import UnstyledButton from '@/shared/components/UnstyledButton.vue'

interface Props {
  clients: Client[]
}

interface Emits {
  (e: 'update'): void
}

const emits = defineEmits<Emits>()
const model = defineModel<string>()
const props = defineProps<Props>()
const filter = ref<string>('')
const isActive = ref(false)

const loadDefault = () => {
  if (model.value) {
    const client = props.clients.find((client: Client) => client.id === model.value)
    filter.value = client ? client.name : ''
  }
}

const filteredOptions = computed((): Client[] => {
  return filter.value === ''
    ? props.clients || []
    : props.clients?.filter(
        (client) => client.name && client.name.toLowerCase().includes(filter.value.toLowerCase()),
      ) || []
})

const updateModel = (client: Client) => {
  filter.value = client.name
  model.value = client.id
  isActive.value = false
  emits('update')
}

const openMenu = () => {
  isActive.value = true
  filter.value = ''
}

const closeMenu = () => {
  isActive.value = false
  loadDefault()
}

const toggleMenu = () => {
  isActive.value = !isActive.value
  if (isActive.value) {
    filter.value = ''
  } else {
    loadDefault()
  }
}

const setValues = (event: Event) => {
  filter.value = (event.target as HTMLInputElement).value
  isActive.value = true
}

watch(model, () => loadDefault(), { immediate: true })
</script>

<template>
  <div class="relative" v-on-click-outside="() => closeMenu()" v-bind="$attrs">
    <div class="flex items-center border border-primary-light">
      <input
        :title="$t('glossary.client')"
        type="text"
        autocomplete="off"
        class="text-sm text-primary-light bg-primary-dark outline-none px-2 py-1 w-full"
        spellcheck="false"
        :name="$t('glossary.client')"
        :value="filter"
        @input="(e) => setValues(e)"
        @click="openMenu()"
        :disabled="clients.length === 1"
      />
      <div class="flex items-center me-2">
        <UnstyledButton v-if="clients.length !== 1" :title="$t('common.open')" @click="toggleMenu()">
          <SvgIcon
            :path="mdiChevronDown"
            class="transform transition fill-primary-light h-5 w-5"
            :class="[isActive ? 'rotate-180' : 'rotate-0']"
          />
        </UnstyledButton>
      </div>
    </div>
    <div
      class="z-40 absolute mt-0.5 border border-gray-300 bg-white transition-all transform ease-in-out duration-200 focus:outline-none w-full overflow-x-auto max-h-64 shadow-md"
      :class="[isActive ? 'opacity-100' : 'opacity-0 collapse']"
    >
      <ul v-for="(client, index) in filteredOptions" :key="index" class="cursor-pointer text-sm">
        <li class="hover:bg-gray-100 px-2 py-1.5" @click="updateModel(client)">{{ client.name }}</li>
      </ul>
      <div v-if="filteredOptions?.length === 0" class="px-2 py-1.5 font-light text-sm">
        {{ $t('message.noResult') }}
      </div>
    </div>
  </div>
</template>
