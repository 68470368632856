import { ref } from 'vue'
import { defineStore } from 'pinia'
import { useApplicationApi } from '@/api/services/application'
import type { MaintenanceScope } from '@/api/models/types'

export const useSystemMaintenanceStore = defineStore('systemMaintenance', () => {
  const current = ref<MaintenanceScope | null>(null)
  const next = ref<MaintenanceScope | null>(null)

  const api = useApplicationApi()

  const doMonitorPoll = async () => {
    const res = (await api.doMonitorPoll()).data
    if (res) {
      current.value = res.currentMaintenance
      next.value = { ...res.nextMaintenance }
    }
  }

  return {
    doMonitorPoll,
    current,
    next,
  }
})
