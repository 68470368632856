import { useUserStore } from '@/modules/user/stores/user'
import { useApi } from '..'
import type {
  ClientErrorData,
  UserClientSettingsSetupResponse,
  Profile,
  UserResponse,
  NoContentResponse,
  UserClientSettings,
  MySelectionOutbound,
} from '../models/types'

export const useUserApi = () => {
  const api = useApi()
  const user = useUserStore()

  const getUser = async (): Promise<{ data: UserResponse | null; error: ClientErrorData | null }> =>
    await api.request.get<UserResponse>('user', true)

  const getUserSettings = async (
    clientId: string | undefined,
  ): Promise<{
    data: UserClientSettingsSetupResponse | null
    error: ClientErrorData | null
  }> => await api.request.get<UserClientSettingsSetupResponse>(`client/${clientId}/user/settings`, true)

  const updateProfile = async (
    payload: Profile,
  ): Promise<{ data: NoContentResponse | null; error: ClientErrorData | null }> =>
    await api.request.patch('profile', false, JSON.stringify(payload))

  const updateSettings = async (
    payload: UserClientSettings,
  ): Promise<{ data: NoContentResponse | null; error: ClientErrorData | null }> =>
    await api.request.patch('client/user/settings', false, JSON.stringify(payload))

  const updateUserFilter = async (
    payload: MySelectionOutbound,
  ): Promise<{ data: NoContentResponse | null; error: ClientErrorData | null }> =>
    await api.request.patch(`view/${user.viewId}/user/myselection`, false, JSON.stringify(payload))

  return { getUser, getUserSettings, updateProfile, updateSettings, updateUserFilter }
}
