import { defineStore } from 'pinia'
import { reactive, ref } from 'vue'
import type { Message } from '../interfaces/item'
import { MESSAGE_DURATION } from '@/constants'

export const useMessageStore = defineStore('message', () => {
  const toastId = ref<NodeJS.Timeout | string | number | undefined>()
  const alertId = ref<NodeJS.Timeout | string | number | undefined>()

  const toast = reactive<Message>({
    type: '',
    text: '',
  })

  const alert = reactive<Message>({
    type: '',
    text: '',
  })

  const showToast = (type: string, text: string | undefined, autoHide: boolean = true) => {
    toast.type = type
    toast.text = text ? text.toString() : ''
    clearTimeout(toastId.value)
    if (autoHide) {
      toastId.value = setTimeout(() => {
        clearToast()
      }, MESSAGE_DURATION)
    }
  }

  // TODO move *Alert into ModalFooter
  const showAlert = (type: string, text: string | undefined) => {
    alert.type = type
    alert.text = text ? text.toString() : ''
    alertId.value = setTimeout(() => {
      clearAlert()
    }, MESSAGE_DURATION)
  }

  const clearToast = () => {
    toast.type = ''
    toast.text = ''
    if (toastId.value) {
      clearTimeout(toastId.value)
    }
  }

  const clearAlert = () => {
    alert.type = ''
    alert.text = ''
    if (alertId.value) {
      clearTimeout(alertId.value)
    }
  }

  const getToast = () => {
    return toast
  }

  const getAlert = () => {
    return alert
  }

  return { showToast, showAlert, getToast, getAlert, clearToast, clearAlert }
})
