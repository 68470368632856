import { useSideMenuApi } from '@/api/services/side-menu'
import { defineStore } from 'pinia'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

export const useSideMenuStore = defineStore('sideMenu', () => {
  const notificationModalImageUrl = ref<string>('')
  const notificationModalImageError = ref<string>('')
  const isOpen = ref<boolean>(false)

  const sideMenuApi = useSideMenuApi()
  const { t } = useI18n()

  const showImage = async (imageId: string) => {
    const getImageUrl = async (imageId: string) => {
      const img = await sideMenuApi.fetchImage(imageId)
      if (!img.data) return null
      return URL.createObjectURL(img.data)
    }
    notificationModalImageUrl.value = ''
    notificationModalImageError.value = ''
    const img = await getImageUrl(imageId)
    if (img) {
      notificationModalImageUrl.value = img
    } else {
      notificationModalImageError.value = t('message.imageNotFound')
    }
  }

  const open = () => {
    isOpen.value = true
  }

  const close = () => {
    isOpen.value = false
  }

  return { isOpen, open, close, showImage, notificationModalImageUrl, notificationModalImageError }
})
