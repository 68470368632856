import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useNavigationStore = defineStore('navigation', () => {
  const isExpanded = ref(false)

  const open = (state: boolean) => {
    isExpanded.value = state
  }

  return { isExpanded, open }
})
