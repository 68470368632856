// Sourced namespace models
// NB This file is auto generated, do not manually alter!


  export enum ApplicationViewKey {
    Inbound = 1000,
    Warehouse = 2000,
    Outbound = 3000,
    Agent = 3300,
    Return = 4000,
  }

  export enum CallOfCommitError {
    UnknownFault = 2,
    OK = 3,
    QuantityOverflow = 10,
    CustomerOrderNoAlreadyExists = 11,
  }

  /**
   * Direct: Direct UserClientAccess binding
   * Sphere: Binding via ClientAccess Sphere
   * SphereMain: Binding via ClientAccess Sphere, this is the main/mother client
   * SphereViaMain: Binding via ClientAccess Sphere, client currently set on user is not the "root" client of sphere
   * DirectOverridingSphere: Binding via ClientAccess Sphere, BUT limited via explicit override by UserClientAccess
   * Implicit: Default "implicit" binding on the User currently set ClientId
   */
  export enum ClientBindingType {
    SA = 0,
    Direct = 1,
    Sphere = 2,
    SphereMain = 3,
    SphereViaMain = 4,
    DirectOverridingSphere = 5,
    Implicit = 6,
  }

  /**
   * agentBrandingMainFontColour: Font used when Main colour used as background
   * agentBrandingTopBannerAlignment: Set the alignment of the side banner. (0=left, 1=center and 2=right)
   * agentBrandingBottomBannerAlignment: Set the alignment of the side banner. (0=left, 1=center and 2=right)
   * agentBrandingFooterColor: Change footer colour from default black for all Application views
   * agentBrandingFooterText: Add a footer text for all Application views
   * callOffViewEnabled: Product Call Off On/Off	Turn on or off Product Call off in OurBoxes navigation menu.
   * Direct: Direct UserClientAccess binding
   * Sphere: Binding via ClientAccess Sphere
   * SphereMain: Binding via ClientAccess Sphere, this is the main/mother client
   * SphereViaMain: Binding via ClientAccess Sphere, client currently set on user is not the "root" client of sphere
   * DirectOverridingSphere: Binding via ClientAccess Sphere, BUT limited via explicit override by UserClientAccess
   * Implicit: Default "implicit" binding on the User currently set ClientId
   * LastActivationConfirmDate: i.e. when the user used the (last) given activation code
   * CreateInvite: Used only when creating a new user.
   * UserAccessLimitationsDto: All values comma seperated strings
   * Int64): Get any explicit user access settings set.
            NB Effectivly most of there are actully limitations
            of what the user make access, since 
            no explicit user access = full access
   * LastActivationConfirmDate: i.e. when the user used the (last) given activation code
   */
  export enum ClientSettings {
    exitToApplicationEnabled = 'exitToApplicationEnabled',
    exitToApplicationUrl = 'exitToApplicationUrl',
    exitToApplicationText = 'exitToApplicationText',
    inboundShipments = 'inboundShipments',
    inboundShipmentsAdminOnly = 'inboundShipmentsAdminOnly',
    warehouseOperations = 'warehouseOperations',
    warehouseOperationsAdminOnly = 'warehouseOperationsAdminOnly',
    outboundShipments = 'outboundShipments',
    outboundShipmentsAdminOnly = 'outboundShipmentsAdminOnly',
    returnShipments = 'returnShipments',
    returnShipmentsAdminOnly = 'returnShipmentsAdminOnly',
    agentView = 'agentView',
    agentViewAdminOnly = 'agentViewAdminOnly',
    agentBrandingFooterText = 'agentBrandingFooterText',
    agentBrandingFooterTextColor = 'agentBrandingFooterTextColor',
    callOffViewEnabled = 'callOffViewEnabled',
    callOffRefToInboundWarehouseClientId = 'callOffRefToInboundWarehouseClientId',
    mySelectionOn = 'mySelectionOn',
    sideMenuProductsOn = 'sideMenuProductsOn',
    contextMenuOn = 'contextMenuOn',
    contextMenuSendMessageOn = 'contextMenuSendMessageOn',
    contextMenuServiceRequestOn = 'contextMenuServiceRequestOn',
    contextMenuShowRelatedOn = 'contextMenuShowRelatedOn',
    contextMenuActionsOn = 'contextMenuActionsOn',
    contextMenuEditInformationOn = 'contextMenuEditInformationOn',
    serviceRequestEnabled = 'serviceRequestEnabled',
    serviceRequestExtendAccessPointDays = 'serviceRequestExtendAccessPointDays',
    serviceRequestChangeName = 'serviceRequestChangeName',
    serviceRequestChangeAddress = 'serviceRequestChangeAddress',
    serviceRequestChangePhone = 'serviceRequestChangePhone',
    serviceRequestChangeEmail = 'serviceRequestChangeEmail',
    serviceRequestCancelShipment = 'serviceRequestCancelShipment',
    serviceRequestChangeDoorAccessCode = 'serviceRequestChangeDoorAccessCode',
    serviceRequestLeaveOutsideDoor = 'serviceRequestLeaveOutsideDoor',
    serviceRequestKnockOnDoor = 'serviceRequestKnockOnDoor',
    serviceRequestNoIdRequirement = 'serviceRequestNoIdRequirement',
    serviceRequestMessageToDriver = 'serviceRequestMessageToDriver',
    serviceRequestChangeLockerLocation = 'serviceRequestChangeLockerLocation',
    serviceRequestNewDeliveryAttempt = 'serviceRequestNewDeliveryAttempt',
    warehouseBalance = 'warehouseBalance',
    isCarrierClientAccount = 'isCarrierClientAccount',
  }

  /**
   * Information: Basic information that is to be seen as positive information, i.e. displayed with a green marker.
   * Normal: Aka "None".
   */
  export enum EventFlag {
    Normal = 'None',
    Information = 'Info',
    Warning = 'Warn',
    Error = 'Error',
    Returned = 'Return',
  }

  /**
   * Low: Matching [CommentStatus] "Information"
   * Medium: Matching [CommentStatus] "Warning"
   * High: Matching [CommentStatus] "High"
   * None: Severity is undefined for item, or set to 0.
   */
  export enum EventSeverity {
    Low = 'Low',
    Medium = 'Med',
    High = 'High',
    None = 'None',
  }

  /**
   * How often user shall be reminded of the upcoming shutdown. In minutes.
   */
  export enum ExposedApplicationSettings {
    PreMaintenanceHintInterval = 214,
  }

  export enum FlagType {
    Info = 1,
    Warning = 2,
    Error = 3,
    Normal = 4,
    Returned = 5,
  }

  export enum MBoxReferenceType {
    CustomerOrder = 0,
    WarehouseOrder = 1,
    Shipment = 2,
    ShipmentGroup = 3,
  }

  export enum MotionItemType {
    CustomerOrder = 'CO',
    WarehouseOrder = 'WO',
    Shipment = 'SH',
    ShipmentGroup = 'SG',
  }

  export enum NotificationCategory {
    All = 10,
    Carrier = 20,
    Contact = 30,
    System = 40,
    MyBoxes = 50,
    User = 60,
  }

  /**
   * Arbitary: Indifferent or arbitary, i.e. event is from any user that is not the "Service Event (PSE)"
   * ServiceEventUser: Event is from the "Service Event (PSE)" user
   */
  export enum OriginServiceType {
    Arbitary = 'Aby',
    ServiceEventUser = 'SUser',
  }

  /**
   * UserManagementEnableCrossClientSerach: Effectivly enable the "clear" button of the client list dropdown in user management.
            Agent users shall not have this option since they can only se user with the same access (or lower)
            that applies the one specific client.
   * UserManagementEnableUserPermissionsEdit: NB to be seen as temporary, remove once user access editeing testing is done
   */
  export enum Permission {
    VOID = 0,
    EnableAdminMenu = 189734708,
    EnableClientSettingsMenu = 283201180,
    EnableUserManagement = 357546462,
    UserManagementEnableCrossClientSerach = 357546483,
    UserManagementEnableUserPermissionsEdit = 357546484,
  }

  /**
   * UserDeleted: Selected client no longer accessobile for user
   * ClientBlocked: Selected client no longer accessobile for any user, i.e. client deactivated
   * RefreshUserProfile: JWT timeout OR TTL mismatch between JWT and user object in cache, OR redis cache droped
   */
  export enum ProfileActionHint {
    UserDeleted = -5,
    ClientInaccessible = -4,
    ClientBlocked = -3,
    UserBlocked = -2,
    UserNotActivated = -1,
    AccessTokenNearEOF = 1,
    RefreshUserProfile = 2,
    AccessTokenRefreshed = 3,
    SystemMaintenancePending = 4,
    SystemMaintenanceActiveUserCanBypass = 5,
  }

  export enum PublicSupportCode {
    Unknown = 1000,
    UserInactivated = 1010,
    ClientNotAccssible = 1020,
    UserBlocked = 1030,
    UserDeleted = 1040,
    MultipleSessionBlock = 2010,
    ClientInactivated = 2020,
    ServiceUnavailable = 3010,
  }

  export enum SeverityType {
    Low = 1,
    Medium = 2,
    High = 3,
    None = 4,
  }

  export enum StatusEventCategory {
    All = 1,
    Status = 2,
    Carrier = 3,
    MyBoxes = 4,
  }

  /**
   * SystemMaintenance: Sent as error response body code when system is in effective maintenance state.
   * LogonConflict: Not sent as error response body code, only indicated as http 409 to client,
            transferd to PublicSupportCode MultipleSessionBlock
   */
  export enum SystemErrorCodes {
    SystemMaintenance = 'SystemMaintenance',
    LogonConflict = 'LogonConflict',
  }

  /**
   * It's actully also a limitation rule, again since no value set = all application views.
   */
  export enum UserAccessType {
    ApplicationView = 10,
    Country = 300,
    ZipZone = 301,
    Carrier = 302,
    ClientServiceGroup = 303,
    Dimension1 = 310,
    Dimension2 = 311,
    Dimension3 = 312,
  }

  /**
   * User: role Admin, Super user, User, Agent
            always user type Ourboxes
   * SystemUser: API YourBoxes, API AddBoxes, Auto Sender
   * Admin: Customer, System
   */
  export enum UserCategory {
    User = 0,
    SystemUser = 1,
    Admin = 2,
  }

  export enum UserRole {
    None = 0,
    Admin = 1,
    SystemService = 2,
    SuperUser = 3,
    User = 4,
    Agent = 5,
  }

  /**
   * zoomLevelDynamicOutbound: Zoom level dynamic (0,1)
   * zoomLevelOutbound: 1=Parcel, 2=Zip, 3=Country
   * tellTail: tellTail binding type
   * mySel_3000_setDates: "Set dates in my selection in Outbound (0 = all dates)"
   * mySel_3000_status: **** Motion view filter settings below ***
            
             
             Requierd status of orders? No, it's "Hide status (0=off, 1-7)"
             AS a bitmask value. E.g. if "shipment" and "pickup" is selected, 
             this value states 3 (1+2)
   * mySel_3000_zipZone: Zone ID filter in Motion view (0 = no filter)
   * mySel_3000_numberOfDaysDashboard: Deprecated?
   * mySel_2000_warehouseId: Warehouse ID filter in Motion view (0 = no filter, value = Warehouse.ID)
   * mySel_2000_departureId: Depature ID filter in Motion view (0 = no filter, value = Depature.ID)
   * mySel_2000_warehouseStatus: Requierd status of orders
   */
  export enum UserSettings {
    languageId = 'languageId',
    defaultApplicationViewId = 'defaultApplicationViewId',
    zoomLevelDynamicOutbound = 'zoomLevelDynamicOutbound',
    zoomLevelOutbound = 'zoomLevelOutbound',
    mySel_3000_clientServiceGroupId = 'mySel_3000_clientServiceGroupId',
    mySel_3000_countryId = 'mySel_3000_countryId',
    mySel_3000_carrierId = 'mySel_3000_carrierId',
    mySel_3000_actionContainerType = 'mySel_3000_actionContainerType',
    mySel_3000_actionContainerId = 'mySel_3000_actionContainerId',
    mySel_3000_setDates = 'mySel_3000_setDates',
    mySel_3000_numberOfDaysDynamic = 'mySel_3000_numberOfDaysDynamic',
    mySel_3000_numberOfDays = 'mySel_3000_numberOfDays',
    mySel_3000_numberOfDaysDashboard = 'mySel_3000_numberOfDaysDashboard',
    mySel_3000_status = 'mySel_3000_status',
    tellTailEnabled = 'tellTailEnabled',
    tellTail = 'tellTail',
    mySel_3000_dimension1Id = 'mySel_3000_dimension1Id',
    mySel_3000_dimension2Id = 'mySel_3000_dimension2Id',
    mySel_3000_dimension3Id = 'mySel_3000_dimension3Id',
    mySel_3000_zipZone = 'mySel_3000_zipZone',
    mySel_2000_warehouseId = 'mySel_2000_warehouseId',
    mySel_2000_departureId = 'mySel_2000_departureId',
    mySel_2000_numberOfDays = 'mySel_2000_numberOfDays',
    mySel_2000_warehouseStatus = 'mySel_2000_warehouseStatus',
    mySel_2000_dimension1valId = 'mySel_2000_dimension1valId',
    mySel_2000_dimension2valId = 'mySel_2000_dimension2valId',
    mySel_2000_dimension3valId = 'mySel_2000_dimension3valId',
  }

  export enum UserState {
    NotActivated = 0,
    Activated = 1,
    Blocked = 2,
  }

  /**
   * DEPRECATEDOurboxesSuperUser: An OurBoxes user, with Settings access right
   * OurboxesUser: An OurBoxes user, with NO settings configuration rights
   * UserActionGroup: DEPRECATED
   */
  export enum UserType {
    Invariant = 0,
    DEPRECATEDOurboxesSuperUser = 10,
    OurboxesUser = 11,
    ReportUser = 30,
    ApiAddBoxes = 100,
    ApiYourBoxes = 1000,
    UserActionGroup = 2000,
    CustomerUser = 2001,
    SystemUser = 2002,
  }

  /**
   * S: ShipmentID
   * R: ReferenceNo
   * I: ClientReferenceNo
   * C: Customer information, i.e. from Recipient name, address, email etc.
   * U: Unknown
   * P: Hit on explicit ParcelID given
   */
  export enum WordAreaType {
    S = 'ShipmentID',
    R = 'ReferenceNo',
    I = 'ClientReferenceNo',
    C = 'CustomerInformation',
    U = 'Unknown',
    P = 'ParcelID',
  }

