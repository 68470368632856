import { useApi } from '..'
import type { ClientErrorData, TokenResponse } from '../models/types'

export const useSessionApi = () => {
  const api = useApi()

  const getResume = async (): Promise<{ data: TokenResponse | null; error: ClientErrorData | null }> =>
    await api.request.get<TokenResponse>('user/resume', true)

  const getAccessToken = async (): Promise<{ data: TokenResponse | null; error: ClientErrorData | null }> =>
    await api.request.get<TokenResponse>('user/refresh-token', true)

  return { getResume, getAccessToken }
}
