<script setup lang="ts">
import { computed, ref } from 'vue'
import { isEqual } from 'lodash'
import { useUserStore } from '../stores/user'
import { mdiContentSave } from '@mdi/js'
import type { OurboxesTargetUriSetup, UserClientSettings, Language } from '@/api/models/types'
import DropdownList from '@/shared/components/DropdownList.vue'
import ModalContainer from '@/shared/components/ModalContainer.vue'
import ModalBody from '@/shared/components/ModalBody.vue'
import ModalFooter from '@/shared/components/ModalFooter.vue'
import DividerLine from '@/shared/components/DividerLine.vue'
import PrimaryButton from '@/shared/components/PrimaryButton.vue'
import DefaultButton from '@/shared/components/DefaultButton.vue'

interface Props {
  views: OurboxesTargetUriSetup[]
  languages: Language[]
  updating: boolean
}

defineProps<Props>()
const open = defineModel<boolean>('open', { required: true })
const userStore = useUserStore()
const defaultApplicationViewId = ref<number>(userStore.viewId!)
const settings = ref<UserClientSettings>({
  languageId: parseInt(userStore.userSettings.languageId),
  defaultApplicationViewId: defaultApplicationViewId.value,
})

const defaultSettings = () => {
  settings.value.languageId = parseInt(userStore.userSettings.languageId)
  settings.value.defaultApplicationViewId = userStore.viewId!
  defaultApplicationViewId.value = userStore.viewId!
}

const onClose = () => {
  open.value = false
  defaultSettings()
}

const onUpdate = () => {
  userStore.updateSettings(settings.value)
  defaultApplicationViewId.value = settings.value.defaultApplicationViewId
}

const hasChanges = computed(
  (): boolean =>
    !isEqual(settings.value.languageId, parseInt(userStore.userSettings.languageId)) ||
    !isEqual(settings.value.defaultApplicationViewId, defaultApplicationViewId.value),
)
</script>

<template>
  <ModalContainer :title="$t('glossary.mySetting', 2)" v-model:open="open" @close="onClose()">
    <ModalBody>
      <DividerLine :title="$t('glossary.applicationSetting', 2)" class="pb-4" />
      <div class="flex pb-4 gap-4">
        <div class="basis-1/2">
          <DropdownList
            v-model="settings.languageId"
            :title="$t('glossary.defaultLanguage')"
            item-key="id"
            item-text="name"
            :items="languages"
          />
        </div>
        <div class="basis-1/2">
          <DropdownList
            v-model="settings.defaultApplicationViewId"
            :title="$t('glossary.defaultApplicationView')"
            item-key="appViewId"
            item-text="label"
            :items="views"
          />
        </div>
      </div>
    </ModalBody>
    <ModalFooter class="justify-end">
      <PrimaryButton
        :text="$t('common.save')"
        class="mr-2"
        :icon="mdiContentSave"
        :loading="updating"
        :disabled="!hasChanges"
        @click="onUpdate()"
      />
      <DefaultButton :text="hasChanges ? $t('common.cancel') : $t('common.close')" @click="onClose()" />
    </ModalFooter>
  </ModalContainer>
</template>
