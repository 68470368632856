import { ref } from 'vue'
import { defineStore } from 'pinia'
import { useSearchApi } from '@/api/services/search'
import { useMessageStore } from '@/shared/stores/message'
import { ERROR } from '@/constants'
import type { SearchResultItem } from '@/api/models/types'

export const useSearchStore = defineStore('search', () => {
  const api = useSearchApi()
  const messageStore = useMessageStore()
  const isSearchActive = ref<boolean>(false)
  const isSearchLoading = ref<boolean>(false)
  const searchResult = ref<{ [item: string]: SearchResultItem[] }>()
  const searchQuery = ref<string>('')
  const filterText = ref<string>('')

  const getSearchResult = async (query: string): Promise<void> => {
    isSearchLoading.value = true
    searchResult.value = undefined
    const { data, error } = await api.getSearchResult(query)
    if (error) {
      messageStore.showToast(ERROR, error.message)
    }
    if (data && data.searchQuery === query) {
      searchResult.value = data.searchResult
      searchQuery.value = data.searchQuery
    }
    isSearchLoading.value = false
  }

  return { isSearchLoading, isSearchActive, searchQuery, searchResult, filterText, getSearchResult }
})
