import { useApi } from '..'
import { useUserStore } from '@/modules/user/stores/user'
import type {
  ClientErrorData,
  MotionUpdateResponse,
  MotionResponse,
  MotionViewFilter,
  OrderFilterListResponse,
  ShipmentFilterListResponse,
  MBox,
  MBoxKpiStatusResponse,
  MotionCheckResponse,
  MotionViewChangeFilter,
  ShipmentFilter,
  MBoxPriorityItem,
  OutboundShipmentsMonitorByIds,
} from '../models/types'

export const useMotionApi = () => {
  const api = useApi()
  const user = useUserStore()

  const fetchMotionItemsZoomLevel10 = async (
    payload: ShipmentFilter,
  ): Promise<{ data: MotionResponse | null; error: ClientErrorData | null }> =>
    await api.request.post(`view/${user.viewId}/motion-view/mboxes-10`, false, JSON.stringify(payload))

  const fetchUpdatedMotionItems = async (
    payload: OutboundShipmentsMonitorByIds,
  ): Promise<{ data: MotionUpdateResponse | null; error: ClientErrorData | null }> =>
    await api.request.post(`view/${user.viewId}/motion-view/update`, false, JSON.stringify(payload))

  const checkForNewMotionItems = async (
    payload: MotionViewChangeFilter,
  ): Promise<{ data: MotionCheckResponse | null; error: ClientErrorData | null }> =>
    await api.request.post(`view/${user.viewId}/motion-view/changepoll`, false, JSON.stringify(payload))

  const fetchSelectedMotionItems = async (payload: {
    priorityItems: MBoxPriorityItem[]
  }): Promise<{ data: MotionUpdateResponse | null; error: ClientErrorData | null }> =>
    await api.request.post(`view/${user.viewId}/search/mboxes-10`, false, JSON.stringify(payload))

  const fetchAggregatedStatus = async (
    payload: MotionViewFilter[],
  ): Promise<{ data: MBoxKpiStatusResponse | null; error: ClientErrorData | null }> =>
    await api.request.post(`view/${user.viewId}/home/mboxes-kpi-status`, false, JSON.stringify(payload))

  //NB Still gives static response
  const getFilters = async (): Promise<{
    data: ShipmentFilterListResponse | OrderFilterListResponse | null
    error: ClientErrorData | null
  }> =>
    await api.request.get<ShipmentFilterListResponse | OrderFilterListResponse>(`view/${user.viewId}/home/my-kpi-setup`)

  const selectMBox = async (mboxId: number): Promise<{ data: MBox | null; error: ClientErrorData | null }> =>
    await api.request.get<MBox>(`view/${user.viewId}/motion-view/mbox/${mboxId}/select`)


  return {
    fetchMotionItemsZoomLevel10,
    fetchUpdatedMotionItems,
    fetchSelectedMotionItems,
    fetchAggregatedStatus,
    checkForNewMotionItems,
    getFilters,
    selectMBox,
  }
}
