<script setup lang="ts">
import { ref, watch } from 'vue'
import { vOnClickOutside } from '@vueuse/components'
import { mdiChevronDown } from '@mdi/js'
import { toKebabCase } from '../utils/format'
import UnstyledButton from './UnstyledButton.vue'
import SvgIcon from './SvgIcon.vue'

interface Props {
  title: string
  items: any[]
  itemKey: any
  itemText: string
  disabled?: boolean
}

const props = defineProps<Props>()
const model = defineModel<any>({ required: true })
const isActive = ref(false)
const text = ref('')
const name = toKebabCase(props.title)

const setDefaultValue = (): void => {
  if (props.items && props.items.length > 0) {
    const match = props.items.find((item) => item[props.itemKey] === model.value)
    if (match) {
      text.value = match[props.itemText]
    } else if (props.items.length > 0) {
      text.value = props.items[0][props.itemText]
      model.value = props.items[0][props.itemKey]
    }
  }
}

const updateModel = (item: any) => {
  text.value = item[props.itemText]
  model.value = item[props.itemKey]
  isActive.value = false
}

watch(model, () => {
  setDefaultValue()
})

setDefaultValue()
</script>

<template>
  <div class="relative" v-on-click-outside="() => (isActive = false)">
    <div class="flex items-center border border-gray-300" :class="{ 'border-dotted': disabled }">
      <input
        type="text"
        autocomplete="off"
        class="peer block text-sm w-full border-0 bg-transparent p-2 outline-none transition-all duration-200 ease-linear motion-reduce:transition-none enabled:cursor-pointer focus:ring-2 focus:ring-gray-700 disabled:bg-white disabled:text-gray-400"
        placeholder=""
        spellcheck="false"
        :name="name"
        :title="title"
        :readonly="true"
        :value="text"
        @click="isActive = true"
        :disabled="disabled"
      />
      <label
        :for="name"
        class="pointer-events-none text-sm absolute left-1 pt-2.5 top-0 mb-0 origin-[0_0] truncate transition-all duration-200 ease-out peer-focus:-translate-y-4 peer-focus:text-xs motion-reduce:transition-none peer-disabled:text-gray-400"
        :class="text ? '-translate-y-4 text-xs' : 'text-sm'"
      >
        <span class="bg-white px-1">{{ title }}</span>
      </label>
      <div class="absolute flex items-center right-1">
        <UnstyledButton :title="$t('common.open')" class="group p-2" @click="isActive = !isActive" :disabled="disabled">
          <SvgIcon
            :path="mdiChevronDown"
            class="transition fill-gray-700 w-5 h-5 group-disabled:fill-gray-400"
            :class="isActive ? 'rotate-180' : 'rotate-0'"
          />
        </UnstyledButton>
      </div>
    </div>
    <div
      v-if="items.length > 0"
      class="z-40 absolute mt-0.5 border border-gray-300 bg-white transition-all transform ease-in-out duration-200 w-full overflow-x-auto max-h-64 focus:outline-none shadow-md"
      :class="isActive ? 'opacity-100' : 'opacity-0 collapse'"
    >
      <ul class="cursor-pointer">
        <li
          class="text-sm truncate hover:bg-gray-100 p-2"
          v-for="(item, index) in items"
          :key="index"
          @click="updateModel(item)"
        >
          <span>{{ item[itemText] }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>
