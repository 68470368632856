import { useApi } from '..'
import type {
  ClientErrorData,
  MonitorTickResponse,
} from '../models/types'

export const useApplicationApi = () => {
  const api = useApi()

  const doMonitorPoll = async (): Promise<{
    data: MonitorTickResponse | null
    error: ClientErrorData | null
  }> => await api.request.get<MonitorTickResponse>(`monitoring/syspoll`, true)

  return {
    doMonitorPoll
  }
}
