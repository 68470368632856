import { ref } from 'vue'
import { useSessionStore } from '@/shared/stores/session'
import { SystemErrorCodes, ProfileActionHint, PublicSupportCode } from '@/api/models/definitions'
import type { ClientErrorData, MaintenanceScope, ResponseBase } from '@/api/models/types'
import { useClientStore } from '@/modules/client/store/client'
import { useSystemMaintenanceStore } from '@/modules/system-maintenance/stores/system-maintenance'

export const useApi = () => {
  const apiPath = import.meta.env.VITE_API_PATH
  const hostAuthUrl = import.meta.env.VITE_AUTH_BASE_URL
  const hostMainUrl = import.meta.env.VITE_MAIN_BASE_URL
  const loginPath = import.meta.env.VITE_AUTH_LOGIN_PATH
  const session = useSessionStore()
  const clientStore = useClientStore()
  const sysMaint = useSystemMaintenanceStore()
  const sysDownResetoreIntervalId = ref<NodeJS.Timeout>()
  const sysPollIntervalAtOngoingMaintenance = 2500
  const firstServiceUnavailable = ref<number | null>(null)
  const maxDuarationOnServiceUnavailableUntilLobbyRedirect = 30000

  const getConfig = (method: string, body?: BodyInit): RequestInit => {
    const request: RequestInit = {
      method,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${session.accessToken}`,
        'Addimotion-Client': clientStore.clientKey,
      },
      mode: 'cors',
      credentials: 'include',
    }
    if (body) {
      request.body = body
    }
    return request
  }

  const request = {
    get: <TResponse>(url: string, useAuthApi: boolean = false) => query<TResponse>(url, useAuthApi, getConfig('GET')),
    getBlob: <TResponse>(url: string) => tryFetchBlob<TResponse>(url, getConfig('GET')),
    put: <TBody extends BodyInit, TResponse>(url: string, useAuthApi: boolean = false, body?: TBody) =>
      query<TResponse>(url, useAuthApi, getConfig('PUT', body)),
    post: <TBody extends BodyInit, TResponse>(url: string, useAuthApi: boolean = false, body?: TBody) =>
      query<TResponse>(url, useAuthApi, getConfig('POST', body)),
    patch: <TBody extends BodyInit, TResponse>(url: string, useAuthApi: boolean = false, body?: TBody) =>
      query<TResponse>(url, useAuthApi, getConfig('PATCH', body)),
    delete: <TBody extends BodyInit, TResponse>(url: string, useAuthApi: boolean = false, body?: TBody) =>
      query<TResponse>(url, useAuthApi, getConfig('DELETE', body)),
  }

  const query = async <TResponse>(
    url: string,
    useAuthApi: boolean,
    config: RequestInit,
  ): Promise<{ data: TResponse | null; error: ClientErrorData | null }> => {
    if (session.isApiLocked() && url.indexOf('/syspoll') === -1) {
      return { data: null, error: {} as ClientErrorData }
    }
    const { data, error } = await tryFetch(`${useAuthApi ? hostAuthUrl : hostMainUrl}${apiPath}/${url}`, config)
    if (error) {
      return { data: null, error: error }
    }
    return { data: data as TResponse, error: error }
  }

  const parseErr = async (bodyOrResponse: any) => {
    const errorData: ClientErrorData = bodyOrResponse.errorData
    if (errorData.code === SystemErrorCodes.SystemMaintenance) {
      sysMaint.current = bodyOrResponse.current as MaintenanceScope
      doSysPoll(false)
    }
    const err = {
      code: errorData.code,
      errClass: errorData.errClass || 'unknown',
      message: errorData.message || 'Unknown error',
      actionHint: errorData.actionHint,
    } as ClientErrorData | null
    delete bodyOrResponse.errorData
    return { data: bodyOrResponse, error: err }
  }

  const tryFetch = async <TResponse>(
    url: string,
    config: RequestInit,
  ): Promise<{ data: TResponse | null; error: ClientErrorData | null }> => {
    try {
      const response = await fetch(url, config)
      if (!response.ok) {
        return await getErrorResponse(response)
      } else {
        const data = await response.json()
        if (data.errorData && data.errorData != 0) {
          return await parseErr(data)
        } else {
          if ((data as ResponseBase).userProfileActionHint === ProfileActionHint.AccessTokenNearEOF) {
            session.refreshAccessToken()
          } else if ((data as ResponseBase).userProfileActionHint === ProfileActionHint.AccessTokenRefreshed) {
            session.accessToken = (data as ResponseBase).accessToken!
          }
          sysMaint.current = null
          firstServiceUnavailable.value = null
          session.unLockApi()
          return { data: data as TResponse, error: null }
        }
      }
    } catch (err: any) {
      doSysPoll(false)
      return {
        data: null,
        error: {
          code: err.name,
          errClass: `fatal::${err}`,
          message: sysMaint.current ? 'System maintenance' : getErrorMessage(err.name),
        },
      }
    }
  }

  const doSysPoll = async (isSelf: boolean) => {
    if (sysDownResetoreIntervalId.value && !isSelf) return
    await sysMaint.doMonitorPoll()
    if (sysMaint.current) {
      sysDownResetoreIntervalId.value = setTimeout(doSysPoll, sysPollIntervalAtOngoingMaintenance, true)
    } else {
      session.unLockApi()
    }
  }

  const tryFetchBlob = async <TResponse>(
    path: string,
    config: RequestInit,
  ): Promise<{ data: TResponse | null; error: ClientErrorData | null }> => {
    try {
      const response = await fetch(`${hostMainUrl}${apiPath}/${path}`, config)
      if (!response.ok) {
        return await getErrorResponse(response)
      } else {
        const data = await response.blob()
        return { data: data as TResponse, error: null }
      }
    } catch (err: any) {
      return {
        data: null,
        error: {
          code: err.name,
          errClass: `fatal::${err}`,
          message: getErrorMessage(err.name),
        },
      }
    }
  }

  const getErrorMessage = (errorName: string): string => {
    if (errorName === 'TimeoutError') {
      return ''
    } else if (errorName === 'AbortError') {
      return ''
    } else if (errorName === 'TypeError') {
      return ''
    } else {
      return ''
    }
  }

  const getErrorResponse = async <TResponse>(
    response: Response,
  ): Promise<{ data: TResponse | null; error: ClientErrorData | null }> => {
    switch (response.status) {
      case 401:
        if (((await response.json()) as ResponseBase).userProfileActionHint === ProfileActionHint.RefreshUserProfile) {
          window.location.assign(`${hostAuthUrl}${loginPath}`)
        }
        return {
          data: null,
          error: {
            code: 'unknown',
            errClass: 'net',
            message: 'General network error, please check your proxy settings',
          },
        }
      case 409:
        window.location.assign(hostAuthUrl + `/lockout?lockOutCode=${PublicSupportCode.MultipleSessionBlock}`)
        return {
          data: null,
          error: {
            code: SystemErrorCodes.LogonConflict,
            errClass: 'security',
            message: await response.text(),
          },
        }
      case 503:
        if (firstServiceUnavailable.value === null) {
          firstServiceUnavailable.value = Date.now()
        }
        if (Date.now() - firstServiceUnavailable.value > maxDuarationOnServiceUnavailableUntilLobbyRedirect) {
          window.location.assign(hostAuthUrl + `/maintenance?lockOutCode=${PublicSupportCode.ServiceUnavailable}`)
        }
        return {
          data: null,
          error: {
            code: SystemErrorCodes.SystemMaintenance,
            errClass: 'system',
            message: await response.text(),
          },
        }
      case 0:
      default:
        return {
          data: null,
          error: {
            code: response.status as unknown as string,
            errClass: 'net',
            message: `Unexpected error ${response.status}, server failed to handle your request`,
          },
        }
    }
  }

  return { request }
}
