<script setup lang="ts">
import { computed, ref } from 'vue'
import { useUserStore } from '../stores/user'
import { vOnClickOutside } from '@vueuse/components'
import { mdiCardAccountDetails, mdiAccountCog, mdiCommentQuestion, mdiLogout } from '@mdi/js'
import UnstyledButton from '@/shared/components/UnstyledButton.vue'
import SvgIcon from '@/shared/components/SvgIcon.vue'
import UserProfileModal from './UserProfileModal.vue'
import UserSettingsModal from './UserSettingsModal.vue'

const userStore = useUserStore()
const isMenuOpen = ref<boolean>(false)
const isProfileOpen = ref<boolean>(false)
const isSettingsOpen = ref<boolean>(false)

const initials = computed((): string => {
  return userStore.profile ? `${userStore.profile.firstName.charAt(0)}${userStore.profile.lastName.charAt(0)}` : ''
})

const signOut = () => {
  window.location.assign(`${import.meta.env.VITE_AUTH_BASE_URL}${import.meta.env.VITE_AUTH_LOGOUT_PATH}`)
}
</script>

<template>
  <div v-if="userStore.profile" v-on-click-outside="() => (isMenuOpen = false)" class="relative inline-block text-left">
    <UnstyledButton
      :title="$t('glossary.profile')"
      @click="isMenuOpen = !isMenuOpen"
      class="inline-flex items-center justify-center w-8 h-8 overflow-hidden bg-secondary-dark text-secondary-light border border-secondary-dark rounded-full"
    >
      <span class="font-medium text-secondary-light uppercase">{{ initials }}</span>
    </UnstyledButton>
    <div
      class="absolute right-0 z-40 mt-1 bg-white border border-gray-300 transition-all transform ease-out duration-200 origin-top-right shadow-md ring-1 ring-gray-900 ring-opacity-5 focus:outline-none"
      :class="[isMenuOpen ? 'opacity-100 scale-100' : 'opacity-0 scale-90 collapse']"
    >
      <div class="border-b border-gray-300 text-xs font-medium truncate p-2">
        {{ userStore.organization }}
      </div>
      <div class="flex p-4">
        <div class="flex items-center justify-center w-16 h-16 bg-gray-500 mr-4 rounded-full">
          <span class="font-medium text-3xl text-white uppercase">{{ initials }}</span>
        </div>
        <div class="flex-row self-center w-52">
          <div class="font-bold text-sm truncate">
            {{ userStore.profile.firstName }} {{ userStore.profile.lastName }}
          </div>
          <div class="text-xs truncate">{{ userStore.profile.emailAddress }}</div>
        </div>
      </div>
      <div class="border-t border-gray-300 text-gray-700 text-sm">
        <div class="group">
          <UnstyledButton
            :title="$t('glossary.yourProfile')"
            class="flex items-center px-4 py-2 group-hover:bg-gray-100 group-hover:text-primary-dark w-full"
            @click="isProfileOpen = true"
          >
            <SvgIcon :path="mdiCardAccountDetails" class="fill-gray-700 group-hover:fill-primary-dark w-5 h-5" />
            <div class="ms-2">{{ $t('glossary.myProfile') }}</div>
          </UnstyledButton>
        </div>
        <div class="group">
          <UnstyledButton
            :title="$t('glossary.userSetting', 2)"
            class="flex items-center px-4 py-2 group-hover:bg-gray-100 group-hover:text-primary-dark w-full"
            @click="isSettingsOpen = true"
          >
            <SvgIcon :path="mdiAccountCog" class="fill-gray-700 group-hover:fill-primary-dark w-5 h-5" />
            <div class="ms-2">{{ $t('glossary.mySetting', 2) }}</div>
          </UnstyledButton>
        </div>
        <div class="group">
          <a target="_blank" href="https://addimotion.com/support">
            <UnstyledButton
              :title="$t('glossary.customerSupport')"
              class="flex items-center px-4 py-2 group-hover:bg-gray-100 group-hover:text-primary-dark w-full"
            >
              <SvgIcon :path="mdiCommentQuestion" class="fill-gray-700 group-hover:fill-primary-dark w-5 h-5" />
              <div class="ms-2">{{ $t('glossary.customerSupport') }}</div>
            </UnstyledButton>
          </a>
        </div>
        <div class="group border-t border-gray-300">
          <UnstyledButton
            :title="$t('glossary.signOut')"
            class="flex items-center px-4 py-2 group-hover:bg-gray-100 group-hover:text-primary-dark w-full"
            @click="signOut"
          >
            <SvgIcon :path="mdiLogout" class="fill-gray-700 group-hover:fill-primary-dark w-5 h-5" />
            <div class="ms-2">{{ $t('glossary.signOut') }}</div>
          </UnstyledButton>
        </div>
      </div>
    </div>
  </div>
  <UserProfileModal v-model:open="isProfileOpen" :updating="userStore.isProfileUpdating" />
  <UserSettingsModal
    v-model:open="isSettingsOpen"
    :views="userStore.views"
    :languages="userStore.languages"
    :updating="userStore.isSettingsUpdating"
  />
</template>
