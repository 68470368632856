import { ref } from 'vue'
import { defineStore } from 'pinia'

const enum UpdateMode {
  Default = 'Default',
  Silent = 'Silent',
  Critical = 'Critical',
}

interface Version {
  buildNumber: number
  buildId: number
  buildType: string
  version: string
}

export const useVersionStore = defineStore('version', () => {
  const buildId = ref<number>(0)
  const buildNumber = ref<number>(0)
  const version = ref<string>('')
  const updateMode = ref({
    isCritical: false,
    isDefault: false,
  })

  const setUpdateMode = (isDefault: boolean, isCritical: boolean) => {
    updateMode.value.isDefault = isDefault
    updateMode.value.isCritical = isCritical
  }

  const setVersion = (newVersion: Version): void => {
    buildId.value = newVersion.buildId
    buildNumber.value = newVersion.buildNumber
    version.value = newVersion.version
  }

  const checkUpdateMode = (buildType: string): void => {
    switch (buildType) {
      case UpdateMode.Default:
        setUpdateMode(true, false)
        return
      case UpdateMode.Critical:
        setUpdateMode(false, true)
        return
      case UpdateMode.Silent:
      default:
        setUpdateMode(false, false)
        return
    }
  }

  const checkVersion = (newVersion: Version): void => {
    if (buildNumber.value && buildNumber.value !== newVersion.buildNumber) {
      checkUpdateMode(newVersion.buildType)
    }
    setVersion(newVersion)
  }

  const fetchVersion = async (): Promise<void> => {
    try {
      const response = await fetch(`/version.json?t=${new Date().getTime()}`)
      if (response) {
        const version = (await response.json()) as Version
        if (version) {
          checkVersion(version)
        }
      }
    } catch (error: any) {
      return error
    }
  }

  return {
    version,
    buildId,
    buildNumber,
    updateMode,
    fetchVersion,
  }
})
