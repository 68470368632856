import { ref } from 'vue'
import { defineStore } from 'pinia'
import type { ApplicationSetting } from '@/api/models/types'

export const useClientStore = defineStore('client', () => {
  const clientKey = ref<string>('')
  const appSettings = ref<ApplicationSetting[]>([])

  const getIntValueSetting = (settingId: number, fallbackValue: number, considerZeroAsNull: boolean = true) => {
    const setting = appSettings.value.find((s) => s.id === settingId)
    if (!setting || (considerZeroAsNull && setting.intValue === 0)) return fallbackValue
    return setting.intValue
  }

  return { clientKey, appSettings, getIntValueSetting }
})
