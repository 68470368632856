<script setup lang="ts">
import { ref } from 'vue'
import { storeToRefs } from 'pinia'
import { mdiUpdate, mdiArrowRightBold, mdiInformationOutline } from '@mdi/js'
import { useVersionStore } from '../stores/version'
import SvgIcon from '@/shared/components/SvgIcon.vue'
import PrimaryButton from '@/shared/components/PrimaryButton.vue'
import DialogPrompt from '@/shared/components/DialogPrompt.vue'

const versionStore = useVersionStore()
const { updateMode } = storeToRefs(versionStore)
const currentVersion = ref<string>(__APP_VERSION__)

const refreshApp = (): void => {
  updateMode.value.isCritical = false
  location.reload()
}
</script>

<template>
  <DialogPrompt :title="$t('glossary.applicationUpdate')" v-model:open="updateMode.isCritical" class="text-center">
    <div class="flex items-center justify-center pb-2">
      <SvgIcon :path="mdiInformationOutline" class="fill-gray-700 h-5 w-5 me-1" />
      <span class="font-medium text-md">{{ $t('message.newMandatoryUpdateAvailable') }}</span>
    </div>
    <div class="flex items-center justify-center text-sm pb-2">
      <div>
        {{ $t('glossary.currentVersion') }}
        <span class="font-bold">{{ currentVersion }}</span>
      </div>
      <SvgIcon :path="mdiArrowRightBold" class="fill-success h-5 w-5 mx-1.5" />
      <div>
        {{ $t('glossary.newVersion') }}
        <span class="font-bold">{{ versionStore.version }}</span>
      </div>
    </div>
    <div class="p-4">
      <PrimaryButton
        :text="$t('glossary.updateNow')"
        :icon="mdiUpdate"
        class="w-full justify-center"
        @click="refreshApp()"
      />
    </div>
  </DialogPrompt>
</template>
