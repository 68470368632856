<script setup lang="ts">
import { INFO, ERROR, SUCCESS, WARNING } from '@/constants'
import { mdiClose, mdiInformation, mdiAlert, mdiCheckCircle, mdiAlertCircle } from '@mdi/js'
import { useMessageStore } from '../stores/message'
import SvgIcon from './SvgIcon.vue'
import UnstyledButton from './UnstyledButton.vue'

const messageStore = useMessageStore()
const toast = messageStore.getToast()
</script>

<template>
  <div
    class="z-50 fixed bottom-11 right-5 transition-transform ease-in-out duration-200"
    :class="[toast.text ? 'translate-x-0' : 'translate-x-32']"
  >
    <div
      class="flex items-center w-full max-w-lg transition-opacity ease-in-out duration-200 p-4 mb-4 text-white bg-gray-900 rounded-lg shadow-lg"
      :class="[toast.text ? 'opacity-100' : 'opacity-0']"
    >
      <div class="inline-flex items-center justify-center flex-shrink-0">
        <SvgIcon v-if="toast.type === INFO" :path="mdiInformation" class="fill-info w-5 h-5" />
        <SvgIcon v-if="toast.type === SUCCESS" :path="mdiCheckCircle" class="fill-success w-5 h-5" />
        <SvgIcon v-if="toast.type === WARNING" :path="mdiAlert" class="fill-warning w-5 h-5" />
        <SvgIcon v-if="toast.type === ERROR" :path="mdiAlertCircle" class="fill-error w-5 h-5" />
        <span class="sr-only">Check icon</span>
      </div>
      <div class="mx-2 text-sm font-normal truncate">{{ toast.text }}</div>
      <UnstyledButton
        :title="$t('common.close')"
        class="ms-auto hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-800 inline-flex items-center justify-center h-8 w-8"
        @click="messageStore.clearToast()"
      >
        <SvgIcon :path="mdiClose" class="fill-white w-5 h-5" />
        <span class="sr-only">Close</span>
      </UnstyledButton>
    </div>
  </div>
</template>
