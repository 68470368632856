import type { RouteLocationNormalized, NavigationGuardNext } from 'vue-router'
import { useSessionStore } from '@/shared/stores/session'
import { storeToRefs } from 'pinia'

export const authGuard = async (
  _to: RouteLocationNormalized,
  _from: RouteLocationNormalized,
  next: NavigationGuardNext,
) => {
  const session = useSessionStore()
  const { accessToken } = storeToRefs(session)

  if (await session.checkForSessionResume()) {
    return next()
  }

  if (!accessToken.value) {
    try {
      if (!(await session.refreshAccessToken())) {
        window.location.assign(loginUrl())
      }
    } catch (e: any) {
      window.location.assign(loginUrl())
      return next(false)
    }
  }

  return next()
}

const loginUrl = (): string => {
  return `${import.meta.env.VITE_AUTH_BASE_URL}${import.meta.env.VITE_AUTH_LOGIN_PATH}`
}
