export class AccordionFocus {
  constructor(isExpanded: boolean, initialFocusKey: number = 0) {
    this._isExpanded = isExpanded
    this.focusedTab = initialFocusKey
  }

  private _isExpanded: boolean = false
  public focusedTab: number = 0
  public showIndicator: boolean = false
  public contentSignature: string = ''

  get isExpanded(): boolean {
    return this._isExpanded
  }

  set isExpanded(value: boolean) {
    this._isExpanded = value
    if (!this._isExpanded) {
      this.contentSignature = ''
    }
  }

  public calcContentSignature = (objectId: number) => `${objectId}-${this.focusedTab}` // TODO add time dependent factor (i.e. "refrech is old")

  public setContentSignature(objectId: number) {
    this.contentSignature = `${objectId}-${this.focusedTab}`
  }
}
